<template>
  <div class="roleManagement flex1">
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right" class="mB20">
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/roleList' }">角色管理</el-breadcrumb-item>
      <el-breadcrumb-item v-if="roleId == 'add'">新建角色</el-breadcrumb-item>
      <el-breadcrumb-item v-if="roleId != 'add'">编辑角色</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="hengBox"></div> -->

    <div class="box1 box_column">
      <div class="textC title emColor">角色信息</div>
      <el-form class="box center" :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item label="角色信息" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请录入"></el-input>
        </el-form-item>
        <el-form-item class="flex1" label="备注" prop="remark">
          <el-input class="flex1" v-model="ruleForm.remark" placeholder="请录入"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="box1 mT30">
      <p class="title emColor textC">角色权限配置</p>
      <ul class="checkMainList">
        <li class="box center hui">
          <span>一级</span>
          <ul class="checkList box box_column flex1">
            <li class="box center">
              <span>二级</span>
              <div class="flex1 textC">权限配置详情</div>
            </li>
          </ul>
        </li>
        <li v-for="firstLevel in checkList" :key="firstLevel.id" class="box center" v-show="firstLevel.type > -1">
          <span class="noFlex">{{ firstLevel.name }}</span>
          <ul class="checkList box box_column flex1">
            <li class="box" v-for="(secondLevel, secondIndex) in firstLevel.children" :key="secondIndex" v-show="secondLevel.type > -1">
              <span>{{ secondLevel.name }}</span>
              <div class="flex1 pl40 box center wrapFlex" id="x1">
                <el-checkbox v-show="threeLevel.type > -1" v-for="threeLevel in secondLevel.children" v-model="ruleForm.permissionId" :label="threeLevel.id" :key="threeLevel.id">
                  {{ threeLevel.name }}
                </el-checkbox>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="box center mT30 horizontal">
      <!-- <el-button @click="save">保存</el-button>
      <div class="btn2" @click="$router.go(-1)">返回</div> -->
      <div class="btnSelect1 mR10" @click="$router.go(-1)">返回</div>
      <div class="btnSelect2" @click="save">保存</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      res: "",
      roleId: "",
      // permissionId: [3,6],
      checkList: [
        {
          id: 1,
          name: "首页",
          child: [{ id: 11, name: "首页", child: [{ id: 111, name: "查看", isChecked: false }] }]
        },
        {
          id: 2,
          name: "基本信息",
          child: [
            { id: 21, name: "桥梁概况", child: [{ id: 211, name: "查看", isChecked: false }] },
            { id: 22, name: "识别信息", child: [{ id: 221, name: "查看", isChecked: false }] },
            {
              id: 23,
              name: "桥梁资料",
              child: [
                { id: 231, name: "查看", isChecked: false },
                { id: 232, name: "操作", isChecked: false }
              ]
            },
            { id: 24, name: "测点布置", child: [{ id: 241, name: "查看", isChecked: false }] },
            {
              id: 25,
              name: "养管信息",
              child: [
                { id: 251, name: "查看", isChecked: false },
                { id: 252, name: "操作", isChecked: false }
              ]
            }
          ]
        },
        {
          id: 3,
          name: "数据管理",
          child: [
            {
              id: 31,
              name: "数据处理设置",
              child: [
                { id: 311, name: "查看", isChecked: false },
                { id: 312, name: "操作", isChecked: false }
              ]
            },
            {
              id: 32,
              name: "数据分析设置",
              child: [
                { id: 321, name: "查看", isChecked: false },
                { id: 322, name: "操作", isChecked: false }
              ]
            }
          ]
        },
        {
          id: 4,
          name: "数据查看",
          child: [
            { id: 41, name: "环境类监测数据", child: [{ id: 411, name: "查看", isChecked: false }] },
            { id: 42, name: "荷载类监测数据", child: [{ id: 421, name: "查看", isChecked: false }] },
            { id: 43, name: "效应类监测数据", child: [{ id: 431, name: "查看", isChecked: false }] },
            { id: 44, name: "数据分析查看", child: [{ id: 441, name: "查看", isChecked: false }] }
          ]
        },
        {
          id: 5,
          name: "预警管理",
          child: [
            {
              id: 51,
              name: "预警设置",
              child: [
                { id: 511, name: "查看", isChecked: false },
                { id: 512, name: "操作", isChecked: false }
              ]
            },
            {
              id: 52,
              name: "预警列表",
              child: [
                { id: 521, name: "查看", isChecked: false },
                { id: 522, name: "操作", isChecked: false }
              ]
            }
          ]
        },
        {
          id: 6,
          name: "设备管理",
          child: [
            {
              id: 61,
              name: "设备信息设置",
              child: [
                { id: 611, name: "查看", isChecked: false },
                { id: 612, name: "操作", isChecked: false }
              ]
            },
            { id: 62, name: "采样参数设置", child: [{ id: 621, name: "查看", isChecked: false }] },
            { id: 63, name: "设备传输控制", child: [{ id: 631, name: "查看", isChecked: false }] }
          ]
        },
        {
          id: 7,
          name: "文档管理",
          child: [
            {
              id: 71,
              name: "建设情况",
              child: [
                { id: 711, name: "查看", isChecked: false },
                { id: 712, name: "操作", isChecked: false }
              ]
            },
            {
              id: 72,
              name: "升级改造",
              child: [
                { id: 721, name: "查看", isChecked: false },
                { id: 722, name: "操作", isChecked: false }
              ]
            },
            {
              id: 73,
              name: "维护记录",
              child: [
                { id: 731, name: "查看", isChecked: false },
                { id: 732, name: "操作", isChecked: false }
              ]
            },
            {
              id: 74,
              name: "月度报告",
              child: [
                { id: 741, name: "查看", isChecked: false },
                { id: 742, name: "操作", isChecked: false }
              ]
            },
            {
              id: 75,
              name: "年度报告",
              child: [
                { id: 751, name: "查看", isChecked: false },
                { id: 752, name: "操作", isChecked: false }
              ]
            }
          ]
        }
      ],
      rules: {
        name: [{ required: true, message: "请录入角色信息", trigger: "blur" }]
      },
      ruleForm: { permissionId: [] }
    };
  },
  mounted() {
    this.getSelectItems();
    this.roleId = this.$route.params.id;
    if (this.roleId != "add") {
      this.getDetail();
      let arr = this.$store.state.breadcrumbList;
      arr.push({ name: "编辑角色" });
      this.$store.commit("getBreadcrumb", arr);
    } else {
      let arr = this.$store.state.breadcrumbList;
      arr.push({ name: "新建角色" });
      this.$store.commit("getBreadcrumb", arr);
    }
  },
  beforeDestroy() {
    let arr = this.$store.state.breadcrumbList;
    arr.splice(2);
    this.$store.commit("getBreadcrumb", arr);
  },
  methods: {
    getSelectItems() {
      this.$axios.get(`${this.baseURL}auth/authPermission/all`).then((res) => {
        console.log("res", res);
        this.res = res.data;
        res = res.data;
        if (res.errCode == 200) this.checkList = res.data;
      });
    },
    getDetail() {
      this.$axios.get(`${this.baseURL}auth/authRole/${this.roleId}`).then((res) => {
        res = res.data;
        if (res.errCode == 200) this.ruleForm = res.data;
      });
    },
    save() {
      this.ruleForm.permissionId = this.ruleForm.permissionId.join(",");
      this.roleId == "add" ? this.handleAdd() : this.handleEdit();
    },
    handleAdd() {
      // console.log('this.ruleFor 添加', this.ruleForm);
      var arr = [];
      var Gbox = document.querySelectorAll("#x1 [type=checkbox]");
      for (var i = 0; i < Gbox.length; i++) {
        console.log(Gbox[i].checked);
        if (Gbox[i].checked == true) {
          console.log("Gbox[i]", Gbox[i].value);
          arr.push(Gbox[i].value);
        }
      }
      var str = arr.toString();
      this.ruleForm.permissionId = str;

      // return;
      this.$axios.post(`${this.baseURL}auth/authRole/`, this.$qs.stringify(this.ruleForm)).then((res) => {
        console.log("提交", res);
        res = res.data;
        if (res.errCode == 200) this.$router.go(-1);
      });
    },
    handleEdit() {
      //   var listString = this.ruleForm.permissionId
      //  var list = listString.split(',')
      var arr = [];
      var Gbox = document.querySelectorAll("#x1 [type=checkbox]");
      for (var i = 0; i < Gbox.length; i++) {
        console.log(Gbox[i].checked);
        if (Gbox[i].checked == true) {
          console.log("Gbox[i]", Gbox[i].value);
          arr.push(Gbox[i].value);
        }
      }
      var str = arr.toString();
      this.ruleForm.permissionId = str;

      // return;
      this.$axios.put(`${this.baseURL}auth/authRole/`, this.$qs.stringify(this.ruleForm)).then((res) => {
        console.log("编辑", res);

        res = res.data;
        if (res.errCode == 200) this.$router.go(-1);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.checkMainList {
  & > li {
    border-bottom: 1px solid #5e5e5f;
    &:first-child {
      border-top: 1px solid #5e5e5f;
    }
  }
  span {
    width: 214px;
    line-height: 53px;
    text-align: center;
  }
  .checkList {
    border-left: 1px solid #5e5e5f;
    li {
      min-height: 70px;
      // line-height: 53px;
      text-align: center;
      border-bottom: 1px solid #5e5e5f;
      span {
        width: 216px;
        border-right: 1px solid #5e5e5f;
        line-height: 53px;
        padding-left: 40px;
        text-align: left;
      }
      .pl40 {
        padding-left: 40px;
      }
      ::v-deep .el-checkbox {
        margin-right: 70px;
      }
      ::v-deep .el-checkbox__inner {
        width: 22px;
        height: 22px;
        border-radius: 4px;
      }
      ::v-deep .el-checkbox__inner::after {
        height: 10px;
        width: 6px;
        margin-left: 2px;
        border-width: 2px;
      }
      ::v-deep span.el-checkbox__input .el-checkbox__inner::after {
        border-color: #e8ff00;
      }
      ::v-deep .el-checkbox__label {
        padding-left: 12px;
      }
    }
  }
}
::v-deep .el-input {
  height: 100%;
}
::v-deep .el-input__inner {
  text-align: center !important;
  border: none !important;
}
.btn2 {
  width: 129px;
  height: 40px;
  cursor: pointer;
  margin-left: 60px;
  background: #1e2529;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  line-height: 40px;
  text-align: center;
}
.roleManagement {
  overflow-y: scroll;
  .hui {
    background: #0b2355;
  }
  .box1 {
    width: 100%;
    background: #071a42;
    // background: rgba(21, 29, 32, 0.52);
    // box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5), 0px 9px 11px 0px rgba(0, 0, 0, 0.5);
    // border: 1px solid #5E5E5F;
    border-radius: 8px;
    opacity: 0.74;
    ::v-deep .el-form {
      .el-form-item {
        margin-bottom: 0;
        border-top: 1px solid #5e5e5f;
        display: flex;
        .el-form-item__label {
          width: 148px;
          height: 54px;
          line-height: 54px;
          padding: 0;
          text-align: center;
          background: #0c265a;
        }
        .el-form-item__content {
          flex: 1;
        }
        .el-input {
          width: 220px;
          &.flex1 {
            width: 100%;
          }
        }
      }
    }
  }
  .title {
    height: 40px;
    line-height: 40px;
  }

  .checkMainList .checkList li {
    // line-height: none !important;
  }
}
</style>
